<template>
  <div class="mail-message">
    <div class="mail-message__header">
      <div v-if="message">
        <strong>{{ message.subject}}</strong> — ({{ folder.title }})
      </div>
      <div class="back grey--text" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
        Назад
      </div>
    </div>
    <div class="mail-message__actions">
      <div class="mail-message__action" @click="removeMessagesModal = true" v-if="hasRight('mail.action')">
        <img width="19" :src="require('@/assets/img/mail/trash.svg')" alt="">
        Удалить
      </div>
      <div class="mail-message__action" @click="sendMailModal = true" v-if="hasRight('mail.send')">
        <img width="19" :src="require('@/assets/img/mail/sent.svg')" alt="">
        Ответить
      </div>
      <div class="mail-message__action revert-icon" @click="forwardMailModal = true" v-if="hasRight('mail.send')">
        <img width="19" :src="require('@/assets/img/mail/sent.svg')" alt="">
        Переслать
      </div>
      <div class="mail-message__action" @click="action('unseen')">
        <img width="19" :src="require('@/assets/img/mail/unread.svg')" alt="">
        Отметить как непрочитанное
      </div>
    </div>
    <div class="mail-message__body" v-if="message">
      <div class="mail-message__info">
        <v-avatar size="35" class="mr-2">
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
        <div class="d-flex flex-column">
          <div class="sender">
            <strong>{{ message.from.name }}</strong>
            <small>({{ message.from.address }})</small>
            <small>{{ message.date | moment('DD.MM.YYYY в HH:mm') }}</small>
          </div>
          <small class="recipient">
            Кому:
            <span class="mr-1">{{message.to.map(i => i.address).join(', ')}}</span>
          </small>
        </div>
      </div>
      <div class="mail-message__content" v-html="message.text.html"></div>
      <div class="attachments__items mt-5" v-if="message.attachments && message.attachments.length">
        <div v-for="item in message.attachments" :key="item.id" class="attachments__item">
          <v-icon @click="downloadFile(item)" size="20" class="mr-2">mdi-download</v-icon>
          <span @click="downloadFile(item)" :title="item.filename">{{ item.filename }}</span>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="sendMailModal"
        max-width="1124"
        transition="slide-x-transition"
        hide-overlay
        v-if="message"
    >
      <SendMail @close="sendMailModal = false" :action="'reply'" :messageId="message.id" :destination="message.from.address" :subject="messageSubject('Re')"/>
    </v-dialog>
    <v-dialog
        v-model="forwardMailModal"
        max-width="1124"
        transition="slide-x-transition"
        hide-overlay
        v-if="message"
    >
      <SendMail @close="forwardMailModal = false" :action="'forward'" :messageId="message.id" :subject="messageSubject('Fwd')"/>
    </v-dialog>
    <v-dialog
        v-model="removeMessagesModal"
        max-width="600"
        @close="removeMessagesModal = false"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление письма
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить письмо?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeMessagesModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="action('delete')"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import SendMail from "../../components/mail/SendMail.vue";
import {hasRight} from "../../utils/access/hasRight";
import exportData from '@/utils/files/requestExport';

export default {
  name: "MessagePage",
  components: {SendMail},
  data() {
    return {
      sendMailModal: false,
      removeMessagesModal: false,
      forwardMailModal: false,
    }
  },
  props: {
    folder: {
      type: Object,
    }
  },
  computed: {
    ...mapState("mail", ["message",]),
  },
  methods: {
    ...mapActions({
      mailAction: 'mail/mailAction',
      downloadAttachment: 'mail/downloadAttachment',
      setFolderIndicator: 'mail/setFolderIndicator',
    }),
    hasRight: hasRight,
    action(action) {
      if (action === 'unseen') {
        this.setFolderIndicator({folder: this.folder, unseen: this.folder?.status?.unseen + 1});
      }
      this.mailAction({
        id: this.$route.params.mailId,
        body: {
          uids: [this.message.uid],
          path: this.$route.params.folder,
          action: action,
        }
      }).then(() => {
        this.$router.go(-1);
      });
    },
    downloadFile (file) {
      const payload = {
        mailId: this.$route.params.mailId,
        fileId: file.id,
      };

      exportData(this.$store.dispatch, 'mail/downloadAttachment', payload, file.filename);
    },
    messageSubject (prefix) {
      if (!this.message.subject.toLowerCase().includes(prefix.toLowerCase())) {
        return `${prefix}: ${this.message.subject}`;
      }
      return this.message.subject;
    },
  }
}
</script>

<style lang="scss">
.mail-message {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DCE1E6;
    padding: 17px 23px;
    font-size: 14px;

    .back {
      cursor: pointer;
    }
  }

  &__body {
    padding: 17px 23px;
    font-size: 14px;
  }

  &__info {
    display: flex;
    margin-bottom: 30px;

    .v-avatar {
      background-color: #44D370 !important;
      border-color: #44D370;
    }

    .sender {
      strong {
        font-size: 13px;
        text-transform: capitalize;
        margin-right: 5px;
      }
    }

    small {
      color: #808386;
      font-size: 12px;
      margin-right: 9px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    padding: 17px 23px;
    border-bottom: 1px solid #DCE1E6;
    font-size: 14px;
    flex-wrap: wrap;
    gap: 27px;
  }

  &__action {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    img {
      margin-right: 5px;
    }

    &.revert-icon {
      img {
        transform: scaleX(-1);
      }
    }
  }

  &__content {
    max-width: 100%;

    span {
      white-space: pre-line;
      word-break: break-word;
    }

    * {
      max-width: 100%;
    }
  }

  .white {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}
</style>
